import React from 'react';
const Environment = () => (
  <div id="environment">
    <div className="segment-header">
      <h3>What about the Environment?</h3>
    </div>
    <div>
      <p>
        In today&#39;s day and age, bringing people together over long distances
        is always a tough decision: What impact will my travel and consumption
        have on the environment? Is it really worth it?
      </p>
      <p>
        We believe that learning, sharing, community, and inclusiveness are
        valid reasons for spending time and resources. But we should still be
        mindful - there is a difference between justified investment, and
        careless waste.
      </p>
      <p>For this reason, SoCraTes 2024 will</p>
      <ul>
        <li>offer giveaways (swag) only via opt-in</li>
        <li>facilitate travel-pooling</li>
        <li>
          encourage off-setting unavoidable air travel carbon emissions via{' '}
          <a
            href="https://atmosfair.de"
            title="The Atmosfair carbon offset organisation"
          >
            Atmosfair
          </a>
        </li>
      </ul>
    </div>
  </div>
);
export default Environment;
