import React, { useEffect, useState } from 'react';
import './Header.scss';
import { useConference } from './ConferenceProvider';
import { Conference, ConferenceState } from './types';
import { eventDate } from '../../utils/eventDate';
import config from '../../config';

export default function Header() {
  const conf = useConference();
  const [conference, setConference] = useState<Conference | undefined>();
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (conference === undefined) {
      conf.conference().then((con) => setConference(con));
    } else {
      setReady(true);
    }
  }, [conf, conference]);

  const logoPath: string = `${config.logoPath}/logos/${conference?.title}_${conference?.year}.png`;
  const conferenceHeader = `${conference?.title} ${conference?.year}`;
  const whenAndWhere: string = `${eventDate(conference?.startDate, conference?.endDate)} • ${conference?.location}`;
  const isRegistrationOpen = conference?.state === ConferenceState.REGISTRATION;
  const registerBtnText: string = isRegistrationOpen
    ? 'Apply for a ticket!'
    : 'Registration will open soon';
  return (
    <div id="header" className="p-5 mb-4 bg-light rounded-3">
      <header className="container socrates-header">
        {!ready && (
          <div
            className={`row justify-content-center ${ready ? 'd-none' : 'd-flex'}`}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {ready && (
          <div className={`row visible`}>
            <div className={`col-lg-4 col-sm-12`}>
              {conference && (
                <img id="logo" src={logoPath} alt={conferenceHeader} />
              )}
            </div>
            <div className={`col-lg-8 col-sm-12`}>
              <h1 className="socrates-title">{conferenceHeader}</h1>
              <h2>{conference?.byline}</h2>
              <h2>{whenAndWhere}</h2>
              {<hr />}
              {isRegistrationOpen && (
                <a
                  className={`btn btn-primary pull-right btn-application`}
                  href="/application"
                >
                  {registerBtnText}
                </a>
              )}
              {!isRegistrationOpen && (
                <button
                  className={`btn btn-primary disabled pull-right btn-application`}
                  type="button"
                >
                  {registerBtnText}
                </button>
              )}
            </div>
          </div>
        )}
      </header>
    </div>
  );
}
