import type { Config } from './ConfigType';

const apiEndpoint: string = 'https://api.socrates-conference.de';
const testEndpoint: string = 'https://api.socrates-conference.de';
const config: Config = {
  conference: 'socrates24',
  logoPath: 'https://socrates-conference.de',
  apiEndpoints: {
    applications: testEndpoint,
    sponsors: apiEndpoint,
    conferences: apiEndpoint,
    applicants: apiEndpoint,
    subscriptions: apiEndpoint,
  },
  isRegistrationOpen: true,
};
export default config;
