import React, { useEffect, useRef } from 'react';
import './Schedule.scss';
import { Link, useLocation } from 'react-router-dom';
import { AbstractKey } from './abstracts';

type CardColour = 'lightblue' | 'paleteal';

export type Track = {
  name: string;
  topic: string;
  colour: CardColour;
  abstract: AbstractKey;
  cancelled?: boolean;
};

interface Session {
  start: string;
  end: string;
  track1: Track;
  track2: Track;
  track3: Track;
  track4: Track;
}

const sessions: Session[] = [
  {
    start: '10:00',
    end: '12:00',
    track1: {
      name: 'Marit van Dijk',
      topic: 'Code Reading (workshop)',
      colour: 'lightblue',
      abstract: 'code_reading_workshop',
    },
    track2: {
      name: 'Bas Vodde',
      topic:
        'Organizational design principles for scaling and a summary of their impact',
      colour: 'paleteal',
      abstract: 'organizational_design',
    },
    track3: {
      name: 'Emily Bache',
      topic: 'Learning Hours - Hands-on Team Building for Developers',
      colour: 'lightblue',
      abstract: 'learning_hours',
      cancelled: true,
    },
    track4: {
      name: 'Michel Grootjans',
      topic: 'Experiencing team flow',
      colour: 'paleteal',
      abstract: 'experiencing_team_flow',
    },
  },
  {
    start: '13:30',
    end: '15:30',
    track1: {
      name: 'Aki Salmi & Sofia Katsaouni',
      topic: 'Expressing gratitude',
      colour: 'paleteal',
      abstract: 'expressing_gratitude',
    },
    track2: {
      name: 'Michael Kutz',
      topic: 'How to untangle your Spaghetti test code',
      colour: 'lightblue',
      abstract: 'untangle_test_code',
    },
    track3: {
      name: 'Thierry de Pauw',
      topic: 'Trunk-based development for regulated environments',
      colour: 'paleteal',
      abstract: 'trunk_based_development',
    },
    track4: {
      name: 'Daniel Bartholomae',
      topic: 'Practical Project Management without Estimates',
      colour: 'lightblue',
      abstract: 'practical_pm_wo_estimates',
    },
  },
  {
    start: '16:00',
    end: '18:00',
    track1: {
      name: 'Marco Emrich',
      topic: 'Workshop: From Vision to Code: Functional Domain Modelling',
      colour: 'lightblue',
      abstract: 'functional_domain_modelling',
    },
    track2: {
      name: 'Lisi Hocke',
      topic:
        'Secure Development Lifecycle Applied - How to Make Things a Bit More Secure than Yesterday Every Day',
      colour: 'paleteal',
      abstract: 'secure_development',
    },
    track3: {
      name: 'Diana Montalion & Andrew Harmel-Law',
      topic: 'Intro to Systems Thinking',
      colour: 'lightblue',
      abstract: 'intro_systems_thinking',
    },
    track4: {
      name: 'Simon Harrer',
      topic: 'Ensemble Programming',
      colour: 'paleteal',
      abstract: 'ensemble_programming',
    },
  },
];

const scheduleAnchor = '#schedule';
export default function Schedule() {
  const scheduleElement = useRef<HTMLAnchorElement>(null);

  const { hash } = useLocation();

  useEffect(() => {
    if (scheduleElement.current && hash === scheduleAnchor) {
      scheduleElement.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [hash]);

  return (
    <div className="schedule">
      <div className="heading">
        <a href={scheduleAnchor} ref={scheduleElement}>
          <h3 className="heading-text">
            <span className="heading-anchor">#</span> Training Day Schedule
          </h3>
        </a>
      </div>

      <div className="session-card">
        <div className="time">
          <span>9:30 - 10:00</span>
        </div>
        <div className="welcome-block">
          <div className="welcome-location"></div>
          <div className="name">Opening</div>
        </div>
      </div>

      {sessions.map((session, index) => (
        <div className="session-card" key={index}>
          <div className="time">
            <span>
              {session.start} - {session.end}
            </span>
          </div>
          <div className={`track ${session.track1.colour}`}>
            <div className="track-number">Track 1 - Hamburg</div>
            <div className="name">{session.track1.name}</div>
            <div className="topic">
              <Link to={`training/abstract/${session.track1.abstract}`}>
                {session.track1.topic}
              </Link>
            </div>
            {session.track1.cancelled && (
              <div className="cancelled">&mdash; Cancelled &mdash;</div>
            )}
          </div>
          <div className={`track ${session.track2.colour}`}>
            <div className="track-number">Track 2 - Erfurt</div>
            <div className="name">{session.track2.name}</div>
            <div className="topic">
              <Link to={`training/abstract/${session.track2.abstract}`}>
                {session.track2.topic}
              </Link>
            </div>
            {session.track2.cancelled && (
              <div className="cancelled">&mdash; Cancelled &mdash;</div>
            )}
          </div>
          <div className={`track ${session.track3.colour}`}>
            <div className="track-number">Track 3 - Hannover</div>
            <div className="name">{session.track3.name}</div>
            <div className="topic">
              <Link to={`training/abstract/${session.track3.abstract}`}>
                {session.track3.topic}
              </Link>
            </div>
            {session.track3.cancelled && (
              <div className="cancelled">&mdash; Cancelled &mdash;</div>
            )}
          </div>
          <div className={`track ${session.track4.colour}`}>
            <div className="track-number">Track 4 - Köln</div>
            <div className="name">{session.track4.name}</div>
            <div className="topic">
              <Link to={`training/abstract/${session.track4.abstract}`}>
                {session.track4.topic}
              </Link>
            </div>
            {session.track4.cancelled && (
              <div className="cancelled">&mdash; Cancelled &mdash;</div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
