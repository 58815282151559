import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { eventDate } from '../../utils/eventDate';
import { useConference } from '../common/ConferenceProvider';
import { Conference } from '../common/types';
import './Sponsoring.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function Sponsoring() {
  const conf = useConference();
  const [conference, setConference] = useState<Conference | undefined>();
  useEffect(() => {
    if (conference === undefined) {
      conf.conference().then((con) => setConference(con));
    }
  }, [conf, conference]);

  const sponsoring =
    'mailto:sponsoring@socrates-conference.de?subject=I%20would%20like%20information%20about%20sponsoring';
  const year = conference ? `${conference.year}` : '';
  const conferenceDate = eventDate(conference?.startDate, conference?.endDate);
  return (
    <div className="container sponsoring-container">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-header">
            <h1>Sponsoring SoCraTes</h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p>Thank you for your interest in making SoCraTes {year} happen!</p>
          <p>
            We invite you to sponsor the conference and help make this a
            brilliant event. Your financial support makes a huge difference to
            the experience, and helps to make sure it will be affordable for our
            attendees.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col card card-body bg-light">
          <h3>About the event</h3>
          <p>
            The next annual International Conference for Software Craft and
            Testing will take place from {conferenceDate} in Soltau, Germany.{' '}
          </p>
          <p>
            We have the entire hotel to ourselves - and up to 220 people can
            participate.
          </p>
          <p>
            The event will be non-commercial, community-driven, and low-price. A
            two-day Open Space, which may include talks, sessions, workshops and
            other formats. SoCraTes is an international non-profit conference
            for craftspeople, by craftspeople.
          </p>
          <p>
            Contact:{' '}
            <a href={sponsoring}>
              <FontAwesomeIcon icon={faEnvelope} />
              <span> sponsoring@socrates-conference.de</span>
            </a>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h2>Our Offer</h2>
          <p>
            Sponsoring SoCraTes means supporting the conference, as well as
            supporting the community of Software Crafters and Testers. It is not
            something you buy - it is something you give. Please understand that
            this means there will not be any sponsor booths, posters and banners
            (except for the{' '}
            <Link to={{ hash: '#stewardships' }} title="Stewardships">
              stewardships
            </Link>{' '}
            mentioned below), nor commercial features, or in-person marketing.
          </p>
          <p>
            We are grateful for your support, of course, and we will show our
            gratitude to all sponsors by
          </p>
          <ul>
            <li>Including your company logo on our website</li>
            <li>
              Posting about the sponsorship on our social media accounts, at the
              time of opening registration, after the first lottery, and before
              and after the main event.
            </li>
            <li>
              Announcing you as a sponsor during the Open Space marketplace, in
              the presence of all attendees
            </li>
            <li>
              Providing a special table in the main conference room to display
              marketing material and giveaways
            </li>
          </ul>
          <p>
            However, in return for your support, we also reward you in
            proportion to your contribution: Tickets for the conference are
            usually distributed via an impartial, randomized lottery process.
            When someone applies for a ticket through this process, they cannot
            be sure they can go, until their name is drawn from the pool of all
            applicants.
          </p>
          <p>
            As a sponsor, you will be awarded up to 5 guaranteed seats at 900
            &euro; each. This means, for example, that you can plan travel
            and/or the contents of an Open Space session for up to 5 of your
            employees, without having to worry about any of them not being able
            to join.
          </p>
          <p>
            For each seat, please still apply via the website. This way, you can
            choose which days to participate on. Please note that the usual
            ticket costs are not covered by the sponsoring and have to be paid
            on top.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <table className="sponsored-seats">
            <thead>
              <tr>
                <th>Contribution Amount</th>
                <th>Guaranteed seats</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>900 &euro;</td>
                <td>1 seat</td>
              </tr>
              <tr>
                <td>1800 &euro;</td>
                <td>2 seats</td>
              </tr>
              <tr>
                <td>2700 &euro;</td>
                <td>3 seats</td>
              </tr>
              <tr>
                <td>3600 &euro;</td>
                <td>4 seats</td>
              </tr>
              <tr>
                <td>4500 &euro;</td>
                <td>5 seats</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-3"></div>
      </div>
      <div className="row">
        <div className="col">
          <h2 id="stewardships">Stewardships</h2>
          <p>
            In addition, there are a limited number of special sponsorship
            offerings. For 3000 &euro;, you can assume stewardship of:
          </p>
          <ul>
            <li>
              <a href="/training" title="Training Day">
                Training Day
              </a>
              , our special one day workshop focused on newcomers.
            </li>
            <li>
              Our{' '}
              <a
                href="https://www.coderetreat.org/the-workshop/"
                title="About the code retreat format"
              >
                code retreat
              </a>{' '}
              event, on the Sunday following the main conference
            </li>
          </ul>
          <p>
            As stewards, your brand will be mentioned in conjunction with the
            event you are stewarding at all times (e.g., &quot;Company X
            Training Day&quot;), and you will have permission to position one
            full-size banner with your company logo - in a central and prominent
            place, at the venue, on the day of the event.
          </p>
          <p>
            Furthermore, you will receive one guaranteed participation seat on
            top.
          </p>
        </div>
      </div>
    </div>
  );
}
