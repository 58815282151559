import socratesPicture from '../../assets/img/socrates2012_history_wall.jpg';
import './history.scss';
import React from 'react';
import { LogoColumn } from '../common/LogoColumn';
export default function History() {
  return (
    <div id="history" className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-header">
            <h1>History</h1>
          </div>
          <p className="extraspace">
            <img
              className="img-fluid"
              src={socratesPicture}
              alt="SoCraTes history wall"
            />
            <small className="float-end">
              Image courtesy of{' '}
              <a
                href="http://www.softwareleid.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Christoph Pater
              </a>
            </small>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="page-header">
            <h3>from 2011 to today.</h3>
          </div>
          <div className="card card-body bg-light">
            <p>
              Back in 2011, the first &ldquo;Software Craftsmanship and Testing
              (un)conference&rdquo; took place in Germany. Since then, the
              community and the event has grown larger each year.{' '}
            </p>
            <p>
              Markus G&auml;rtner has written a nice{' '}
              <a
                href="http://www.shino.de/2014/03/03/socrates-2014/"
                target="_blank"
                rel="noopener noreferrer"
              >
                blog post
              </a>{' '}
              in 2014, which sums up some details why and how SoCraTes was
              established. He graciously allowed us to quote parts of it in the
              text that follows.{' '}
            </p>
          </div>
          <div className="segment-header">
            <h3>
              2010 <small>The Beginning</small>
            </h3>
          </div>
          <div>
            <p>
              Andreas Leidig and Nicole Rauch started the whole thing way back
              in late 2010. They wanted to get a discussion going regarding a
              conference on Software Craftsmanship in Germany. We decided to
              meet up during{' '}
              <a
                href="http://www.xpdays.de"
                target="_blank"
                rel="noopener noreferrer"
              >
                XP Days Germany
              </a>{' '}
              in 2010, and see what we could do. We quickly agreed on an
              Unconference format, two days, somewhere laid back. Some folks had
              organised the German Agile Coach Camp and Play4Agile in
              Rückersbach close to Frankfurt. We decided on that spot as well,
              and organised everything for 2011.
            </p>
            <p>
              Early on, we figured that we would need outside support. That was
              when we started to reach out to other craftspeople, like Micah
              Martin, Adewale Oshineye, Sandro Mancuso, and many, many more. We
              had around 10-20 participants from outside Germany with us at the
              first SoCraTes. All the tales they shared with us on how they were
              running things in London, Israel, Finland, you-name-it engaged us.
              It felt good to be around so many like-minded folks, and receive
              outside inspiration.
            </p>
          </div>
          <div className="segment-header year-2011">
            <h3>
              2011 <small>First year</small>
            </h3>
          </div>
          <div>
            <p>
              The first SoCraTes - Software Craftsmanship and Testing
              (un)conference - was a success. We had some track sessions back
              then, and a full day of Open Space. During the Open Space I joined
              a session that took a look on how to spread the community. With
              all the energy in the place, we put ourselves on a virtual map of
              Germany. That was when I noticed, oh, there are a bunch of other
              folks around me that come from a similar location as I do. That
              was also when we decided that we needed to keep that spirit going.
              We talked to folks in our neighborhood, and found soon some
              company for local user groups to keep on spreading the word.{' '}
            </p>
          </div>
          <div className="segment-header year-2012">
            <h3>
              2012 <small>Second year</small>
            </h3>
          </div>
          <div>
            <p>
              One year later, we came back for SoCraTes 2012. Since the first
              conference we had established 10 user groups all over Germany on
              Software Craftsmanship, all organised under the banner of{' '}
              <a
                href="http://softwerkskammer.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Softwerkskammer
              </a>
              . There was one in Hamburg, one in Karlsruhe, one in Munich, one
              in Nürnberg, one in Berlin, one in Frankfurt, one in Dortmund, one
              in Düsseldorf, and one shared around Münster, Osnabrück, and
              Bielefeld. We created a timeline of events of what had happened in
              the various local communities since our first get-together.
            </p>
            <p>
              We were amazed about the various events, code retreats, user group
              meetings, and so on.
            </p>
            <p>
              We still adhered to reserve space for foreign inspirations at that
              time. We had 10-20 people from outside Germany with us. However,
              Rückersbach had just 70 beds overall available. With ten local
              user groups potentially joining our Unconference, we faced a
              serious problem. From each location just around 5 people would be
              able to join. So, with such a large community, we already excluded
              many potential attendees.
            </p>
            <p>
              The format of the conference had shifted though. We had abandoned
              previously-set track sessions all-together. Instead we focused on
              two full days of Open Space. That provided the freedom necessary.
              In the end, we decided to run the conference again in Rückersbach,
              but have it organised by a different group of people. We
              explicitly decided to pass over the organising responsibility to
              one of the local groups from year to year.
            </p>
          </div>
          <div className="segment-header year-2013">
            <h3>
              2013 <small>Third year</small>
            </h3>
          </div>
          <div>
            <p>In 2013, the limited amount of beds became a problem.</p>
            <p>
              Notwithstanding the size, Rückersbach had an advantage: it was
              close to Frankfurt airport (about a one hour ride by car). That
              made it easy for people from other countries to attend, since
              Frankfurt is the largest airport in Germany. It would be hard to
              find such a spot with more beds in such a good position.
            </p>
            <p>
              We discussed again what to do about it, and asked the organisers
              to seek a location that may scale up to 200 participants.
            </p>
          </div>
          <div className="segment-header year-2014">
            <h3>
              2014 <small>Fourth year</small>
            </h3>
          </div>
          <div>
            <p>
              This year we switched to a new location. The choice fell on
              Soltau, a small town conveniently located about an hour&#39;s
              drive away from Hamburg. The hotel gives off a family vibe and has
              enough rooms and outside locations to enable leisure sessions
              outdoors. It can easily support 200 participants and so provides
              room for growth.
            </p>
            <p>
              2014 turned out to be a great year. We had grown significantly, to
              150 participants. People loved the new location and in general
              seemed to have a good time. There were two days of Open Space
              followed by a day of workshops.
            </p>
            <p>
              The new location set up an intimate feel for the whole event: cozy
              outside sessions, plenty of opportunities for group discussions of
              varying sizes and a nice bar area enabling quality evening time.
              Discussions tended to last deep into the night or even until
              sunrise. On the way back to our normal lives everybody seemed to
              promise to meet again at the same place next year.
            </p>
          </div>
          <div className="blog-articles">
            <h4>Blog articles 2014</h4>
            <ul className="blog-article-list">
              <li>
                <a href="https://8thlight.com/blog/doug-bradbury/2014/08/12/socrates-2014-a-vibrant-german-software-craftsmanship-community.html">
                  <b>Doug Bradbury:</b> SoCraTes 2014—A Vibrant German Software
                  Craftsmanship Community
                </a>
              </li>
              <li>
                <a href="https://www.ikusalic.com/blog/2014/08/11/socrates-2014/">
                  <b>Ivan Kusalic:</b> SoCraTes 2014
                </a>
              </li>
              <li>
                <a href="https://thephp.cc/news/2014/08/socrates">
                  <b>Sebastian Bergmann:</b> SoCraTes
                </a>
              </li>
              <li>
                <a href="http://www.shino.de/2014/03/03/socrates-2014/">
                  <b>Markus Gärtner:</b> SoCraTes 2014
                </a>
              </li>
            </ul>
          </div>
          <div className="segment-header year-2015">
            <h3>
              2015 <small>Fifth year</small>
            </h3>
          </div>
          <div>
            <p>
              This was the second year in Soltau. If possible, the location felt
              even more inviting this year - it was familiar by now. We had 160
              participants, a maximum given the conference dates. We faced an
              unexpected situation - the schedule for open space was over 80%
              full on both days. The energy and desire to contribute was
              heartwarming and inspiring to observe.
            </p>
            <p>
              Too many amazing things happend to note them all down, so instead
              here is a short{' '}
              <a
                href="https://www.youtube.com/watch?v=8XpB30AWE4Y"
                target="_blank"
                rel="noopener noreferrer"
              >
                song
              </a>{' '}
              that shows the SoCraTes spirit. The only thing left to say is: see
              you next year in Soltau.{' '}
            </p>
          </div>
          <div className="blog-articles">
            <h4>Blog articles 2015</h4>
            <ul className="blog-article-list">
              <li>
                <a href="https://blog.pagansoft.de/articles/socrates2015/">
                  <b>Martin Gondermann:</b> SoCraTes 2015 - or how to be
                  catmatic
                </a>
              </li>
              <li>
                <a href="https://www.todaysoftmag.com/article/1700/socrates-2015-unconference">
                  <b>Mihnea Lazăr:</b> SoCraTes 2015 Unconference
                </a>
              </li>
              <li>
                <a href="https://roth-soft.de/software-craftsmanship/socrates2015-this-is-home-my-crowd-my-family/">
                  <b>Stephan Roth:</b> „This is home. This is my crowd. My
                  family.“
                </a>
              </li>
              <li>
                <a href="https://www.thilko.com/2015/08/30/socrates-2015/">
                  <b>Thilko Richter:</b> SoCraTes 2015
                </a>
              </li>
              <li>
                <a href="http://www.carlosble.com/2015/09/notes-from-socrates-2015/">
                  <b>Carlos Blé Jurado: </b> Notes from Socrates 2015
                </a>
              </li>
            </ul>
          </div>
          <div className="segment-header year-2016">
            <h3>
              2016 <small>Sixth year</small>
            </h3>
          </div>
          <div>
            <p>
              The first year in which we could have the Soltau location
              exclusively. This pushed the atmosphere to a new level of
              intimacy. We have been almost 240 people attending. This also
              maxes out the hotel - but we no longer want to grow: It is about
              as big as we want it to be.{' '}
            </p>
          </div>
          <div className="blog-articles">
            <h4>Blog articles 2016</h4>
            <ul className="blog-article-list">
              <li>
                <a href="https://www.cgarbs.de/blog/archives/1138-SoCraTes-2016.html">
                  <b>Mitch:</b> SoCraTes 2016
                </a>
              </li>
            </ul>
          </div>
          <div className="segment-header year-2017">
            <h3>
              2017 <small>Seventh year</small>
            </h3>
          </div>
          <div>
            <p>
              The second time we had the Soltau location exclusively, and we put
              in extra effort to make the event as welcoming and inclusive as
              possible, to the largest amount of people we could think of.{' '}
            </p>
            <p>
              Most of the many new ideas we put in motion, such as having
              gender-neutral bathrooms, were direct results from an intense
              workshop we ran at the 2016 conference, ensuring that the
              conference attendees remain at the root of what happens every
              year.
            </p>
            <p>
              Resulting from two equally amazing 2017 sessions on how we see
              ourselves as a community, the conference also changed its name:
              We, along with a large number of local communities from all over
              Europe, dropped the awkward &quot;manship&quot;, to make it
              obvious that our focus is and remains on the craft itself, and
              that we welcome everyone, from everywhere, regardless of gender.
            </p>
            <p>
              The feedback we received after the 2017 conference, not only from
              those people belonging to marginalized groups, encouraged us that
              our community is as healthy as ever, and frankly the most
              warm-hearted and open environment we can dream of.{' '}
            </p>
          </div>
          <div className="blog-articles">
            <h4>Blog articles 2017</h4>
            <ul className="blog-article-list">
              <li>
                <a href="https://lebenplusplus.de/2017/08/27/impressions-from-socrates-2017/">
                  <b>Gabriel Birke:</b> Impressions from SoCraTes 2017
                </a>
              </li>
              <li>
                <a href="https://www.methodpark.de/blog/socrates-unconference-2017/">
                  <b>Frank Blendinger:</b> SoCraTes 2017
                </a>
              </li>
            </ul>
          </div>
          <div className="segment-header year-2018">
            <h3>
              2018 <small>Eighth year</small>
            </h3>
          </div>
          <div>
            <p>
              In 2018, we had Juke as our facilitator for the first time. They
              stepped in on a short notice and did a great job to pave the road
              for an amazing SoCraTes Conference.
            </p>
            <p>
              While some of the concerence requlars publicly announced that they
              would like to be conference buddies, nobody took up the offer.
              Instead we received feedback, that the conference is very
              welcoming and everyone seems to naturally include new attendees
              and introverted people.
            </p>
          </div>
          <div className="blog-articles">
            <h4>Blog articles 2018</h4>
            <ul className="blog-article-list">
              <li>
                <a href="https://developer.epages.com/blog/events/socrates-2018/">
                  <b>Jan M. , Oliver Z.:</b> SoCraTes 2018 - More than just
                  developers
                </a>
              </li>
              <li>
                <a href="https://coderbyheart.com/conferencebuddy-at-socrates-2018/">
                  <b>Markus Tacker:</b> ConferenceBuddy at SoCraTes 2018
                </a>
              </li>
              <li>
                <a href="https://codurance.com/2018/08/28/socrates-germany-2018/">
                  <b>Francesco Gigli:</b> SoCraTes Germany 2018
                </a>
              </li>
              <li>
                <a href="https://schmonz.com/2018/08/28/socrates-2018/">
                  <b>Amitai Schleier:</b> SoCraTes 2018
                </a>
              </li>
            </ul>
          </div>
          <div className="segment-header year-2019">
            <h3>
              2019 <small>Ninth year</small>
            </h3>
          </div>
          <div>
            <p>
              In 2019, it was once again Juke who guided us through the smooth
              waters of Open Space Technology.
            </p>
            <p>
              The conference was amazing as always, with highlights including a
              very popular session on nail polish (with lots of male-presenting
              participants enjoying colorful keyboard hands), and an evening of
              ensemble-programming a unit test framework in C64 Basic.
            </p>
          </div>
          <div className="blog-articles">
            <h4>Blog articles 2019</h4>
            <ul className="blog-article-list">
              <li>
                <a href="https://medium.com/@programmiri/do-we-need-safe-spaces-for-minoritised-people-in-tech-682d100f262f">
                  <b>Mirjam B&auml;uerlein:</b> Do we need safe spaces for
                  minoritised people in tech?
                </a>
              </li>
              <li>
                <a href="https://coderbyheart.com/i-wore-a-t-shirt-demanding-inclusivity-at-a-tech-conference-and-this-is-what-happened">
                  <b>Markus Tacker:</b> I wore a T-Shirt demanding Inclusivity
                  at a Tech Conference and this is what happened
                </a>
              </li>
              <li>
                <a href="https://www.rewe-digital.com/inside-rewe-digital/show/socrates-2019.html">
                  <b>Paul Jacks, Stefan Scheidt:</b> SoCraTes 2019
                </a>
              </li>
              <li>
                <a href="https://lebenplusplus.de/2019/08/25/notes-from-socrates-2019/">
                  <b>Leben++ (Gabriel Birke):</b> Notes from Socrates 2019
                </a>
              </li>
            </ul>
          </div>
          <div className="segment-header year-2020">
            <h3>
              2020 <small>The pandemic hit :(</small>
            </h3>
          </div>
          <div>
            <p>
              In 2020, everything was different. Instead of a joyful tenth
              anniversary, we had to cancel the conference, like so many others
              did. It was a sad year for all of us. <br />
            </p>
          </div>
          <div className="segment-header year-2021">
            <h3>
              2021 <small>Virtual edition</small>
            </h3>
          </div>
          <div>
            <p>
              Still very much in the grasp of the pandemic, we decided to make
              SoCraTes 2021 a virtual event. Using SpatialChat, we were able to
              create a dynamic and creative atmosphere that allowed for
              well-rehearsed presentations, as well as spontaneous fun and happy
              accidents. Almost 100 people attended and made the weekend a
              worthwhile experience that in positivity and spirit came close to
              the real thing - a much needed infusion of geek fun in dark times.
            </p>
          </div>
          <div className="segment-header">
            <h3>
              2022 <small>Tenth year</small>
            </h3>
          </div>
          <div>
            <p>
              After having to cancel our beloved conference in 2020 due to
              COVID-19 complications, and a virtual conference in 2021, we were
              happy to have a full-fledged SoCraTes at our usual location in
              Soltau again.
            </p>
            <p>
              The biggest change over the last editions was the{' '}
              <em>Foundations Day</em>: A one-day event, preceding the main
              conference, where distinguished community members tought newcomers
              and interested folks some of the basic aspects of software
              crafting and testing.
            </p>
          </div>
          <div className="blog-articles">
            <h4>Blog articles 2022</h4>
            <ul className="blog-article-list">
              <li>
                <a href="https://coderbyheart.com/socrates-2022-be-prepared-to-be-challenged">
                  <b>Markus Tacker:</b> SoCraTes 2022 &mdash; be prepared to be
                  challenged
                </a>
              </li>
              <li>
                <a href="https://blog.mayflower.de/13953-socrates-2022-konferenzbericht.html">
                  <b>Eric / Mayflower GmbH:</b> SoCraTes 2022 – ein
                  Konferenzbericht
                </a>
              </li>
              <li>
                <a href="https://www.lisihocke.com/2022/09/socrates-2022-a-welcoming-community-of-people-learning-together.html">
                  <b>Lisi Hocke:</b> SoCraTes 2022 - A Welcoming Community of
                  People Learning Together
                </a>
              </li>
              <li>
                <a href="https://www.gernotpointner.de/2022/09/05/socrates-2022.html">
                  <b>Gernot Pointner:</b> My first visit to Socrates Conference
                </a>
              </li>
              <li>
                <a href="https://msdeibel.github.io/conference/software/craft/2022/09/05/socrates-2022.html">
                  <b>Markus Deibel:</b> SoCraTes 2022 - Finally back in Soltau
                </a>{' '}
                (
                <a href="https://msdeibel.github.io/de/conference/software/craft/2022/09/05/socrates-2022.html">
                  German version
                </a>
                )
              </li>
            </ul>
          </div>

          <div className="segment-header">
            <h3>
              2023 <small>Eleventh Year</small>
            </h3>
          </div>
          <div>
            <p>
              In response to the positive feedback from the previous year's
              successful "Foundation Day" and the community's expressed desire
              for a deeper dive into specific topics, this year we introduced
              "Training Day". The Training Day was tailored for both newcomers
              and experienced professionals, offering a blend of beginner and
              expert sessions to provide a comprehensive learning experience in
              the realm of software crafting.
            </p>
            <p>
              Additionally, to make the conference more inclusive and
              accommodating for attendees with families, child care services
              were introduced this year.
            </p>
            <p>
              Full schedule of offered sessions can be found{' '}
              <a href="http://web.archive.org/web/20230828104402/https://scrts.de/socrates2023/index.html">
                here
              </a>{' '}
            </p>
          </div>
          <div className="blog-articles">
            <h4>Blog articles 2023</h4>
            <ul className="blog-article-list">
              <li>
                <a href="https://niklaslochschmidt.com/blog/2023/organizing-socrates-2023">
                  <b>Niklas Lochschmidt:</b> Co-Organizing SoCraTes Conference
                  2023
                </a>
              </li>
              <li>
                <a href="https://blog.axels.cloud/posts/2023-08-26-socrates2023-raising-kids-embracing-diversity/">
                  <b>Axel Schüssler:</b> Raising Kids Embracing Diversity -
                  SoCraTes 2023 Session
                </a>
              </li>
              <li>
                <a href="https://blog.mayflower.de/16014-socrates-2023.html">
                  <b>Eric:</b> SoCraTes 2023
                </a>
              </li>
              <li>
                <a href="https://ymmv.craftswerk.io/2023/08/socrates-2023">
                  <b>Raimo Radczewski:</b> SoCraTes 2023 - SoCraTes is bowling
                  today
                </a>
              </li>
              <li>
                <a href="https://www.lisihocke.com/2023/09/socrates-2023-a-place-where-i-belong.html">
                  <b>Lisi Hocke:</b> SoCraTes 2023 - A Place Where I Belong
                </a>
              </li>
              <li>
                <a href="https://medium.com/@immernurwollen/socrates-germany-2023-b436f7069">
                  <b>Martin Schmidt:</b> SoCraTes Germany 2023
                </a>
              </li>
            </ul>
          </div>
        </div>
        <LogoColumn />
      </div>
    </div>
  );
}
